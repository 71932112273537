import { useEffect, useMemo, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { Html } from '@react-three/drei'

import { truncate } from '@/lib/truncate'
import { View, ImageData } from '@/lib/api/client'
import { CameraPlusIcons } from '@/components/icons/CameraPlusIcon'
import { useAssemblyTree } from '@/state'
import { Button } from '@/components/ui/button'
import { useViewCreateMutation } from '@/pages/CADPage/queries'
import { useCADQuery } from '@/services/queries/cads'
import { useListImages } from '@/services/queries/images'
import { useListViews } from '@/services/queries/views'
import { useListImageTemplates } from '@/services/hooks/template_attributes'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { useCADPageStore } from '@/pages/CADPage/state'
import { getMaxViewsInDocument } from '@/utils/document_preview'
import { Editor } from '@/components/editor/Editor'
import { Skeleton } from '@/components/ui/skeleton'
import { useUpdateEditorNotes } from '@/pages/DocumentPage/components/OperationNotesEditor/hooks/useUpdateEditorNotes'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogClose,
} from '@/components/ui/dialog'

export const CreateViewButton = () => {
  const { isLoading, data } = useCADQuery()
  const { data: documentData } = useDocumentPageQuery()
  const documentType = documentData?.documentType

  const { isLoading: isLoadingViews, data: viewsData } = useListViews({
    cadVersionId: data.version?.id as string,
    documentVersionId: data.documentVersion?.id as string,
  })

  const { isLoading: isLoadingImages, data: images } = useListImages({
    documentVersionId: data.documentVersion?.id as string,
  })

  const selectedPartUUID = useCADPageStore((state) =>
    state.explosionsToolbar && state.focusedPartUUID
      ? state.focusedPartUUID
      : state.selectedParts.length === 1
        ? state.selectedParts[0]
        : null,
  )
  const selectedParts = useCADPageStore((state) =>
    state.explosionsToolbar && state.focusedPartUUID
      ? [state.focusedPartUUID]
      : state.selectedParts,
  )

  const explosions = useCADPageStore((state) => state.explosions)
  const hiddenParts = useCADPageStore((state) => state.hiddenParts)
  const colorMap = useCADPageStore((state) => state.colorMap)
  const transparentParts = useCADPageStore((state) => state.transparentParts)
  const cameraAspect = useCADPageStore((state) => state.cameraAspect)
  const cameraPosition = useCADPageStore((state) => state.cameraPosition)
  const cameraFov = useCADPageStore((state) => state.cameraFov)
  const cameraZoom = useCADPageStore((state) => state.cameraZoom)
  const cameraQuaternion = useCADPageStore((state) => state.cameraQuaternion)
  const cameraUp = useCADPageStore((state) => state.cameraUp)
  const isExplosionLinesEnabled = useCADPageStore(
    (state) => state.isExplosionLinesEnabled,
  )
  const renderMode = useCADPageStore((state) => state.renderMode)

  const {
    data: createViewData,
    mutate: createView,
    isPending: isViewCreationPending,
    isSuccess,
  } = useViewCreateMutation({
    documentType: documentType ?? 'project_tracker',
  })

  useEffect(() => {
    if (isSuccess && selectedPartUUID && data) {
      const documentPages = data.documentPages || []
      const docPageIndex = documentPages.findIndex(
        (page) => page.assembly_group_id === selectedPartUUID,
      )
      if (docPageIndex === -1) return
      document.querySelector(`#op${docPageIndex + 1}`)?.scrollIntoView()
      window.location.hash = `op${docPageIndex + 1}`
    }
  }, [data, isSuccess, selectedPartUUID])

  const getNodeByUUID = useAssemblyTree(useShallow((state) => state.getNode))

  const gltf = data?.gltf
  const views = (viewsData || []).filter((view) =>
    selectedParts.includes(view.assembly_group_id),
  )

  const relatedViews = views.filter(
    (view) => view.assembly_group_id === selectedPartUUID,
  )

  const projectTrackerView = views.filter(
    (view) =>
      view.assembly_group_id === selectedPartUUID &&
      createViewData?.viewID === view.id,
  )[0]

  const documentPage = data?.documentPages?.find(
    (page) => page.assembly_group_id === selectedPartUUID,
  )

  const imageIds = useListImageTemplates(documentPage?.id || '').map(
    (template) => template.template_values.imageId,
  )

  const relatedImages = (images || []).filter((image) => {
    if (!documentPage) return false

    return imageIds.find((id) => id === image.id)
  })

  const totalViews = relatedViews.length + relatedImages.length

  const isLoadingPreview =
    !!projectTrackerView &&
    !!projectTrackerView?.id &&
    !projectTrackerView?.download_url

  if (!selectedPartUUID || !gltf || !documentType) {
    return null
  }

  const handleCreateView = () => {
    const image_data: ImageData = {
      cameraAspect,
      cameraPosition,
      cameraFov,
      cameraZoom,
      cameraQuaternion,
      cameraUp,
      explosions,
      hiddenParts,
      colorMap,
      transparentParts,
      isExplosionLinesEnabled,
      renderMode,
    }

    const treeNode = getNodeByUUID(selectedPartUUID)
    const viewOrderInDocument = calculateNewScreenshotOrder()
    const partName =
      treeNode?.display_name ||
      treeNode?.product ||
      treeNode?.instance ||
      'part'

    const viewName =
      documentType === 'work_instructions'
        ? `${projectName}-${partName}-${cadVersionNumber}`.replace(' ', '-')
        : documentType === 'project_tracker'
          ? `${projectName}-${partName}-${cadVersionNumber}-${new Date()
              .toISOString()
              .replace(/[:.TZ]/g, '')}`.replace(' ', '-')
          : documentType === 'visual_bom'
            ? `${projectName}-${partName}`.replace(' ', '-')
            : `${projectName}-${partName}-${cadVersionNumber}`.replace(' ', '-')

    if (cadVersionId && treeNode && projectId && cadId) {
      createView({
        cadId,
        cadVersionId,
        docVersionId,
        documentPageId:
          documentType === 'work_instructions' ? treeNode.document_page_id : '',
        assemblyPartUUID: selectedPartUUID,
        projectId,
        values: {
          download_url: null,
          source_type:
            documentType === 'visual_bom' ? 'isometric' : 'screenshot',
          name: truncate(viewName),
          image_data,
          cad_version: cadVersionId,
          assembly_group_id: selectedPartUUID,
          document_page_order: viewOrderInDocument,
          dynamic:
            documentType === 'visual_bom' || documentType === 'project_tracker'
              ? false
              : true,
        },
      })
    }
  }

  if (isLoading) {
    return <Html>Loading...</Html>
  }

  const maxViewsInDocument = getMaxViewsInDocument(documentType)

  const isCreateViewButtonDisabled =
    isLoadingViews ||
    isLoadingImages ||
    totalViews >= maxViewsInDocument ||
    isViewCreationPending ||
    !data?.isLatestCadVersion

  const cadId = data?.cad?.id
  const cadVersionId = data?.version?.id
  const docVersionId = data?.documentVersion?.id
  const projectName = data.project?.name || 'project'
  const projectId = data.project?.id
  const cadVersionNumber = data.version?.version_number || 1

  const calculateNewScreenshotOrder = () => {
    for (let i = 0; i < maxViewsInDocument; i++) {
      const viewFound = relatedViews.find(
        (view) => view.document_page_order === i,
      )
      if (!viewFound) return i
    }
  }

  return (
    <>
      {documentType === 'work_instructions' && (
        <CreateWorkInstructionsViewButton
          isCreateViewButtonDisabled={isCreateViewButtonDisabled}
          handleCreateView={handleCreateView}
        />
      )}
      {documentType === 'project_tracker' && (
        <CreateProjectTrackerViewButtonModal
          isCreateViewButtonDisabled={!data?.isLatestCadVersion}
          isLoadingPreview={isLoadingPreview}
          handleCreateView={handleCreateView}
          documentPageId={createViewData?.documentPageId as string}
          view={projectTrackerView}
          documentId={documentData.documentId as string}
          projectId={projectId as string}
          selectedPartUUID={selectedPartUUID}
          display_name={getNodeByUUID(selectedPartUUID)?.display_name || ''}
        />
      )}
    </>
  )
}

const CreateWorkInstructionsViewButton = ({
  isCreateViewButtonDisabled,
  handleCreateView,
}: {
  isCreateViewButtonDisabled: boolean
  handleCreateView: React.MouseEventHandler<HTMLButtonElement> | undefined
}) => {
  const setHover = useCADPageStore((state) => state.setCreateViewButtonHover)
  const { data: documentPageData } = useDocumentPageQuery()
  const isLatestDocumentVersion = useMemo(
    () => documentPageData?.isLatestDocumentVersion || false,
    [documentPageData?.isLatestDocumentVersion],
  )

  return (
    <Html
      wrapperClass="transform-none w-56 h-36 bg-transparent !z-20 !bottom-0 !right-0 !left-auto !top-auto"
      className="h-full w-full bg-transparent"
    >
      <div className="h-full w-full flex items-center p-4 justify-evenly">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                className="w-16 h-16 rounded-full shadow-md"
                disabled={
                  isCreateViewButtonDisabled || !isLatestDocumentVersion
                }
                onClick={handleCreateView}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <div className="flex items-center space-x-2">
                  <CameraPlusIcons className="h-8 w-8 relative left-px stroke-white" />
                </div>
              </Button>
            </TooltipTrigger>
            <TooltipContent className="bg-black text-white">
              <p>
                {isLatestDocumentVersion
                  ? 'Capture view'
                  : 'Cannot capture view in the current document version'}
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </Html>
  )
}

const CreateProjectTrackerViewButtonModal = ({
  isCreateViewButtonDisabled,
  isLoadingPreview,
  handleCreateView,
  documentPageId,
  view,
  projectId,
  documentId,
  selectedPartUUID,
  display_name,
}: {
  isCreateViewButtonDisabled: boolean
  isLoadingPreview: boolean
  handleCreateView: React.MouseEventHandler<HTMLButtonElement> | undefined
  documentPageId: string
  view: View
  projectId: string
  documentId: string
  selectedPartUUID: string
  display_name: string
}) => {
  const [note, setNote] = useState('')
  const setHover = useCADPageStore((state) => state.setCreateViewButtonHover)
  const { isPending, mutateAsync: updateNotes } = useUpdateEditorNotes()
  const { data: documentPageData } = useDocumentPageQuery()
  const isLatestDocumentVersion = useMemo(
    () => documentPageData?.isLatestDocumentVersion || false,
    [documentPageData?.isLatestDocumentVersion],
  )

  return (
    <Html
      wrapperClass="transform-none w-56 h-36 bg-transparent !z-20 !bottom-0 !right-0 !left-auto !top-auto"
      className="h-full w-full bg-transparent"
    >
      <Dialog>
        <div className="h-full w-full flex items-center p-4 justify-evenly">
          <DialogTrigger asChild>
            <Button
              className="w-16 h-16 rounded-full shadow-md"
              disabled={isCreateViewButtonDisabled || !isLatestDocumentVersion}
              onClick={handleCreateView}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <div className="flex items-center space-x-2">
                <CameraPlusIcons className="h-8 w-8 relative left-px stroke-white" />
              </div>
            </Button>
          </DialogTrigger>
          <DialogContent
            style={{ zIndex: 50 }}
            withCloseButton={true}
            onInteractOutside={(e) => {
              e.preventDefault()
            }}
            className="w-96 min-h-80 transform left-[30%] top-[60%]"
          >
            <div className="flex flex-col items-center w-4/5 h-full m-auto">
              <DialogTitle className="text-center pb-1.5">
                {display_name}
              </DialogTitle>
              <div className="flex items-center flex-1 flex-col gap-2 w-full">
                {isLoadingPreview ? (
                  <Skeleton className="bg-gray-200 w-[264px] h-[254px]" />
                ) : (
                  <div className="w-[264px] h-[254px]">
                    <img src={view?.download_url as string}></img>
                  </div>
                )}
                <div className="w-full max-h-68">
                  <Editor
                    documentId={documentId}
                    projectId={projectId}
                    documentPageId={documentPageId}
                    assemblyGroupId={selectedPartUUID}
                    onUpdate={(e) => {
                      setNote(e.editor.getHTML())
                    }}
                    content={note}
                    placeholder="Add notes"
                  />
                </div>
                <DialogClose asChild>
                  <Button
                    disabled={isPending}
                    className="rounded-full p-1 w-full"
                    onClick={async () => {
                      await updateNotes({
                        documentPageId: documentPageId,
                        note: {
                          content: note,
                          viewId: view?.id as string,
                        },
                      })

                      setNote('')
                    }}
                  >
                    {isPending ? 'loading...' : 'Add Note'}
                  </Button>
                </DialogClose>
              </div>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </Html>
  )
}
