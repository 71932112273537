import { Node } from '@tiptap/core'

export const Submit = Node.create({
  addKeyboardShortcuts() {
    return {
      Enter: () => {
        const { onSubmit } = this.options
        const { isEmpty, commands } = this.editor
        const content = this.editor.getHTML()

        if (onSubmit) {
          setTimeout(() => {
            commands.deleteCurrentNode()
          })

          if (!isEmpty) {
            onSubmit(content)
          }
        }

        return false
      },
    }
  },
})
