import { useEffect } from 'react'

import { Spinner } from '@/components/ui/spinner'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { usePrevious } from '@/utils/hooks'

import { WorkInstructions } from './WorkInstructions'
import { ProjectTracker } from './ProjectTracker'

export const Documents = () => {
  const { isLoading, data } = useDocumentPageQuery()
  const prevIsLoading = usePrevious(isLoading)

  useEffect(() => {
    if (!isLoading && prevIsLoading) {
      const searchParams = Object.fromEntries(
        new URLSearchParams(window.location.search),
      )

      if (searchParams.page_id) {
        setTimeout(() => {
          const pageId = searchParams.page_id
          const page = document.getElementById(pageId)

          if (page) {
            page.scrollIntoView()
          }
        }, 100)
      }
    }
  }, [isLoading, prevIsLoading])

  if (isLoading) {
    return <Spinner />
  }

  return data?.documentType === 'project_tracker' ? (
    <ProjectTracker />
  ) : (
    <WorkInstructions />
  )
}
