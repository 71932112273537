import { useState, useEffect, useCallback, ChangeEvent, useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'

import { TableHead } from '@/components/ui/table'
import {
  CustomColumn,
  CustomRow,
  useCreateElementPosition,
  useCreatePartsCustomColumn,
  useCreatePartsCustomColumnsData,
  useCreatePartsCustomRow,
  useDeletePartsCustomColumns,
  useDeletePartsCustomColumnsData,
  useDeletePartsCustomRow,
  useListPartsCustomColumns,
  useListPartsCustomColumnsData,
  useListPartsCustomRows,
  usePartsTablePosition,
  useUpdateElementPosition,
  useUpdatePartsCustomColumns,
  useUpdatePartsCustomColumnsData,
  useUpdatePartsCustomRow,
} from '@/services/hooks/template_attributes'
import type { DocumentPage } from '@/lib/api/client'
import { EditableTable, createNewColumn } from './EditableTable'
import { useDocumentState } from '@/state/document'

export type OperationTableData = {
  partName: any
  quantity: number | string
  assemblyGroupId: string
  [key: string]: any
}

type OperationTableProps = {
  initialTableData: Array<OperationTableData>
  initialColumns: Array<ColumnDef<OperationTableData>>
  isReadOnly?: boolean
  assemblyGroupId: string
  documentPage: DocumentPage
}
export const OperationTable = ({
  initialTableData,
  initialColumns,
  isReadOnly = false,
  assemblyGroupId,
  documentPage,
}: OperationTableProps) => {
  const [tableData, setTableData] = useState(() => [...initialTableData])
  const [columnsDef, setColumnsDef] = useState(() => [...initialColumns])
  const [columnsData, setColumnsData] = useState(
    initialColumns.reduce((acc, column) => {
      acc[(column as any).accessorKey] = column.header
      return acc
    }, {}),
  )
  const partsCustomColumns = useListPartsCustomColumns(documentPage.id)
  const partsCustomColumnsData = useListPartsCustomColumnsData(documentPage.id)
  const partsCustomRows = useListPartsCustomRows(documentPage.id)
  const tablePosition = usePartsTablePosition(documentPage.id)

  const { mutateAsync: createPartCustomColumn } = useCreatePartsCustomColumn()
  const { mutateAsync: updatePartCustomColumn } = useUpdatePartsCustomColumns()
  const {
    mutateAsync: deleteCustomColumns,
    isPending: isDeletingCustomColumns,
  } = useDeletePartsCustomColumns()
  const { mutateAsync: createPartsCustomColumnsData } =
    useCreatePartsCustomColumnsData()
  const { mutateAsync: updatePartsCustomColumnsData } =
    useUpdatePartsCustomColumnsData()
  const {
    mutateAsync: deletePartsCustomColumnsData,
    isPending: isDeletingCustomColumnsData,
  } = useDeletePartsCustomColumnsData()
  const { mutateAsync: createPartsCustomRow } = useCreatePartsCustomRow()
  const { mutateAsync: updatePartsCustomRow } = useUpdatePartsCustomRow()
  const { mutateAsync: deletePartsCustomRow } = useDeletePartsCustomRow()
  const { mutateAsync: updateTablePosition, isPending: isUpdatingPosition } =
    useUpdateElementPosition()
  const { mutateAsync: createTablePosition, isPending: isCreatingPosition } =
    useCreateElementPosition()
  const isDeleting = useMemo(
    () =>
      isDeletingCustomColumns ||
      isDeletingCustomColumnsData ||
      isUpdatingPosition ||
      isCreatingPosition,
    [
      isDeletingCustomColumns,
      isDeletingCustomColumnsData,
      isUpdatingPosition,
      isCreatingPosition,
    ],
  )

  const setSelectedElement = useDocumentState(
    (state) => state.setSelectedElement,
  )

  const draggableId = useMemo(() => 'parts-table', [])
  const tableType = useMemo(() => 'parts-table', [])

  const selectTable = useCallback(() => {
    setSelectedElement({
      documentPageId: documentPage.id as string,
      id: draggableId,
      isRemovable: false,
    })
  }, [documentPage.id, draggableId, setSelectedElement])

  const selectedElement = useDocumentState((state) => state.selectedElement)
  const isSelected = useMemo(
    () =>
      selectedElement?.documentPageId === documentPage.id &&
      selectedElement?.id === draggableId,
    [selectedElement, documentPage.id, draggableId],
  )

  const deleteTableHandler = useCallback(async () => {
    if (tablePosition) {
      const cleanPayload = {
        ...tablePosition.template_values,
      }

      delete cleanPayload.position
      cleanPayload.removed = true
      await updateTablePosition({
        templateAttributeId: tablePosition.id as string,
        payload: cleanPayload,
      })
    } else {
      await createTablePosition({
        documentPageId: documentPage.id as string,
        payload: {
          movableElementId: draggableId,
          removed: true,
          type: tableType,
        },
      })
    }

    if (partsCustomColumns) deleteCustomColumns(partsCustomColumns.id as string)
    if (partsCustomColumnsData)
      deletePartsCustomColumnsData(partsCustomColumnsData.id as string)
    if (partsCustomRows && partsCustomRows.length) {
      Promise.all(
        partsCustomRows.map((row) => deletePartsCustomRow(row.id as string)),
      )
    }
  }, [
    updateTablePosition,
    createTablePosition,
    deleteCustomColumns,
    deletePartsCustomColumnsData,
    deletePartsCustomRow,
    documentPage.id,
    tablePosition,
    draggableId,
    tableType,
    partsCustomColumns,
    partsCustomColumnsData,
    partsCustomRows,
  ])

  const onRemove = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        deleteTableHandler()
      }
    },
    [deleteTableHandler],
  )

  useEffect(() => {
    if (isSelected && selectedElement?.isRemovable) {
      document.addEventListener('keydown', onRemove)
    } else {
      document.removeEventListener('keydown', onRemove)
    }

    return () => {
      document.removeEventListener('keydown', onRemove)
    }
  }, [onRemove, isSelected, selectedElement?.isRemovable])

  useEffect(() => {
    const customColumns =
      partsCustomColumns?.template_values as unknown as Array<CustomColumn>

    if (customColumns) {
      setColumnsData((prev) => ({
        ...prev,
        ...customColumns.reduce(
          (accum, customColumn) => ({
            ...accum,
            ...customColumn,
          }),
          {},
        ),
      }))

      customColumns.forEach((column) => {
        Object.keys(column).forEach((accessorKey) => {
          const columnDef = createNewColumn(
            accessorKey,
            onCellChange,
            selectTable,
            assemblyGroupId,
            isReadOnly,
          ) as ColumnDef<OperationTableData>
          setColumnsDef((prev) => [...prev, columnDef])
        })
      })
    }

    /* This is needed because the app is running in strict mode so it runs this hook twice */
    return () => {
      setColumnsDef(() => [...initialColumns])
    }
  }, [partsCustomColumns])

  useEffect(() => {
    const partsCustomTableData =
      (partsCustomColumnsData?.template_values as unknown as Array<OperationTableData>) ||
      []
    const rows: Array<CustomRow> = partsCustomRows.map(
      (row) => row.template_values,
    )

    setTableData((prev) => [
      ...prev.map((row, i) => ({
        ...row,
        ...((partsCustomTableData[i] as any) || {}),
      })),
      ...rows,
    ])

    return () => {
      setTableData(() => [...initialTableData])
    }
  }, [partsCustomColumnsData?.template_values, partsCustomRows])

  const onCellChange = useCallback(
    (rowIndex, columnId, value) => {
      setTableData((prev) =>
        prev.map((row, index) =>
          index === rowIndex ? { ...row, [columnId]: value } : row,
        ),
      )
    },
    [setTableData],
  )

  const onHeaderChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, accessorKey: string) => {
      const target = event.target
      const value = target.value

      setColumnsData((prev) => ({
        ...prev,
        [accessorKey]: value,
      }))
    },
    [],
  )

  const savePartsCustomColumnsData = useCallback(() => {
    const filteredTableData = tableData
      .map((row) => {
        return Object.keys(row).reduce((acc, accessorKey) => {
          if ((accessorKey as string).includes('custom-column')) {
            acc[accessorKey] = row[accessorKey]
          }

          return acc
        }, {})
      })
      .slice(0, initialTableData.length)

    if (!partsCustomColumnsData) {
      createPartsCustomColumnsData({
        documentPageId: documentPage.id as string,
        rowData: filteredTableData[0],
      })
    } else {
      updatePartsCustomColumnsData({
        templateAttributeId: partsCustomColumnsData.id as string,
        rowsData: filteredTableData,
      })
    }
  }, [
    documentPage.id,
    initialTableData.length,
    tableData,
    partsCustomColumnsData,
    createPartsCustomColumnsData,
    updatePartsCustomColumnsData,
  ])

  const savePartsCustomRow = useCallback(
    (rowIndex: number) => {
      const partsCustomRowTemplate = partsCustomRows?.at(
        rowIndex - initialTableData.length,
      )
      const row = tableData[rowIndex]

      if (!row) return

      if (partsCustomRowTemplate) {
        updatePartsCustomRow({
          templateAttributeId: partsCustomRowTemplate.id as string,
          customRow: row,
        })
      } else {
        createPartsCustomRow({
          documentPageId: documentPage.id as string,
          customRow: row,
        })
      }
    },
    [
      documentPage.id,
      initialTableData.length,
      partsCustomRows,
      tableData,
      updatePartsCustomRow,
      createPartsCustomRow,
    ],
  )

  const savePartsTableData = useCallback(
    ({ rowIndex, isCustomRow }: { rowIndex: number; isCustomRow: boolean }) => {
      if (isCustomRow) {
        savePartsCustomRow(rowIndex)
      } else {
        savePartsCustomColumnsData()
      }
    },
    [savePartsCustomColumnsData, savePartsCustomRow],
  )

  const savePartsCustomColumns = useCallback(() => {
    const filteredColumns = columnsDef
      .filter((column) =>
        ((column as any).accessorKey as string).includes('custom-column'),
      )
      .map((column) => {
        const accessorKey = (column as any).accessorKey
        return { [accessorKey]: columnsData[accessorKey] }
      })

    // if no custom columns found for this document page, we create the template attribute
    if (!partsCustomColumns) {
      createPartCustomColumn({
        documentPageId: documentPage.id as string,
        customColumn: filteredColumns[0],
      })
      // if custom columns found for this document page, we update the template attribute
    } else {
      updatePartCustomColumn({
        templateAttributeId: partsCustomColumns.id as string,
        customColumns: filteredColumns,
      })
    }
  }, [
    columnsData,
    columnsDef,
    partsCustomColumns,
    documentPage.id,
    createPartCustomColumn,
    updatePartCustomColumn,
  ])

  const addNewColumn = useCallback(
    ({
      prevAccessorKey,
      newColumn,
    }: {
      prevAccessorKey: string
      newColumn: ColumnDef<any>
    }) => {
      const accessorKey = (newColumn as { accessorKey: string }).accessorKey
      const columnBefore =
        columnsDef.findIndex(
          (column) => (column as any).accessorKey === prevAccessorKey,
        ) || columnsDef.length - 1

      setColumnsDef((prevColumns) => [
        ...prevColumns.slice(0, columnBefore + 1),
        newColumn as ColumnDef<OperationTableData>,
        ...prevColumns.slice(columnBefore + 1),
      ])
      setTableData((prevTableData) =>
        prevTableData.map((row) => ({
          ...row,
          [accessorKey]: '',
        })),
      )
    },
    [columnsDef, setColumnsDef, setTableData],
  )

  const removeColumn = useCallback(
    (accessorKey: string) => {
      const columnBefore = columnsDef.findIndex(
        (column) => (column as any).accessorKey === accessorKey,
      )

      if (columnBefore === -1) return
      setColumnsDef((prevColumns) => [
        ...prevColumns.slice(0, columnBefore),
        ...prevColumns.slice(columnBefore + 1),
      ])
      setTableData((prevTableData) =>
        prevTableData.filter((row) => Object.keys(row).includes(accessorKey)),
      )
      setColumnsData((prev) => {
        const newColumns = { ...prev }
        delete newColumns[accessorKey]
        return newColumns
      })

      const filteredPartsCustomColumnsData = tableData
        .map((row) => {
          return Object.keys(row).reduce((acc, currAccessorKey) => {
            if (
              (currAccessorKey as string).includes('custom-column') &&
              (currAccessorKey as string) !== accessorKey
            ) {
              acc[currAccessorKey] = row[currAccessorKey]
            }

            return acc
          }, {})
        })
        .filter((row) => Object.keys(row).length)
        .slice(0, initialTableData.length)

      const filteredCustomRows = tableData
        .map((row) => {
          return Object.keys(row).reduce((acc, currAccessorKey) => {
            if ((currAccessorKey as string) !== accessorKey) {
              acc[currAccessorKey] = row[currAccessorKey]
            }

            return acc
          }, {})
        })
        .filter((row) => Object.keys(row).length)
        .slice(initialTableData.length, tableData.length)

      const filteredColumns = columnsDef
        .filter(
          (column) =>
            ((column as any).accessorKey as string).includes('custom-column') &&
            ((column as any).accessorKey as string) !== accessorKey,
        )
        .map((column) => {
          const accessorKey = (column as any).accessorKey
          return { [accessorKey]: columnsData[accessorKey] }
        })

      updatePartCustomColumn({
        templateAttributeId: partsCustomColumns?.id as string,
        customColumns: filteredColumns,
      })

      updatePartsCustomColumnsData({
        templateAttributeId: partsCustomColumnsData?.id as string,
        rowsData: filteredPartsCustomColumnsData,
      })

      Promise.all(
        partsCustomRows.map((customRow, i) =>
          updatePartsCustomRow({
            templateAttributeId: customRow.id as string,
            customRow: filteredCustomRows[i],
          }),
        ),
      )
    },
    [
      columnsDef,
      columnsData,
      partsCustomRows,
      partsCustomColumns?.id,
      partsCustomColumnsData?.id,
      tableData,
      updatePartsCustomRow,
      setColumnsDef,
      updatePartCustomColumn,
      updatePartsCustomColumnsData,
    ],
  )

  const removeRow = useCallback(
    (index: number) => {
      setTableData((prev) => prev.filter((_, i) => i !== index))
      const partsCustomRowTemplate = partsCustomRows?.at(
        index - initialTableData.length,
      )

      if (partsCustomRowTemplate) {
        deletePartsCustomRow(partsCustomRowTemplate.id as string)
      }
    },
    [partsCustomRows, initialTableData.length, deletePartsCustomRow],
  )

  const addNewRow = useCallback((newRow: any) => {
    setTableData((prev) => [...prev, newRow])
  }, [])

  useEffect(() => {
    if (tablePosition?.template_values.columnsSize) {
      setColumnsDef((prevColumns) =>
        prevColumns.map((column) => {
          const accessor = (column as any).accessorKey
          const size = (tablePosition?.template_values.columnsSize as any)[
            accessor
          ]
          if (size) {
            return {
              ...column,
              size,
            }
          }
          return column
        }),
      )
    }
  }, [tablePosition?.template_values.columnsSize])

  return (
    !tablePosition?.template_values.removed && (
      <EditableTable
        saveTableData={savePartsTableData}
        onCellChange={onCellChange}
        removeRow={removeRow}
        addRow={addNewRow}
        onHeaderChange={onHeaderChange}
        saveCustomColumns={savePartsCustomColumns}
        removeColumn={removeColumn}
        addColumn={addNewColumn}
        documentPageId={documentPage?.id as string}
        assemblyGroupId={assemblyGroupId}
        tableData={tableData}
        defaultRows={initialTableData.length}
        defaultColumns={initialColumns.length}
        columnsDef={columnsDef as any}
        columnsData={columnsData}
        maxRows={initialTableData.length + 2}
        maxColumns={4}
        positionData={tablePosition?.template_values.position}
        isDeleting={isDeleting}
        id={draggableId}
        type={tableType}
        isReadOnly={isReadOnly}
        additionalColumns={[
          <TableHead
            key="table-head--id"
            className="relative border-x border-solid border-gray-200 border-collapse text-xs h-auto py-1"
            style={{
              width: 35,
            }}
          >
            Id
          </TableHead>,
        ]}
      />
    )
  )
}
