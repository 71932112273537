import { DocumentTemplate } from '../../DocumentTemplate'
import { View } from '@/lib/api/client'
import { AnnotationsContainer } from '../../Annotations/AnnotationsContainer'
import type { DocumentPage } from '@/lib/api/client'
import DraggableImage from '../../DraggableImage'
import { useAnnotationsQuery } from '../../Annotations/queries'
import {
  useViewsPositions,
  useNotesPositions,
  useListViews,
} from '@/services/hooks/template_attributes'
import { OperationNotesEditor } from '../../OperationNotesEditor/OperationNotesEditor'

interface ProjectsTrackerPageProps {
  projectId: string
  documentId: string
  partName: string
  assemblyGroupId: string
  pageId: string
  views: View[]
  isReadOnly: boolean
  documentPage: DocumentPage
}

export const ProjectsTrackerPage = ({
  documentId,
  projectId,
  pageId,
  partName,
  assemblyGroupId,
  views,
  isReadOnly,
  documentPage,
}: ProjectsTrackerPageProps) => {
  const { isAddingAnnotation } = useAnnotationsQuery()
  const viewsPositions = useViewsPositions(documentPage.id)
  const notesPositions = useNotesPositions(documentPage.id)
  const viewAttributes = useListViews(documentPage.id)
  const viewId = viewAttributes[0]?.template_values?.viewId || ''
  const view = views.find((view) => view.id === viewId)

  const userFacingDownloadUrl = `${view?.name}.jpg`
  const downloadUrl = view?.download_url || ''

  const viewPosition = viewsPositions.find(
    (position) =>
      position.template_values.movableElementId ===
      `view--${view?.document_page_order}`,
  )
  const notePosition = notesPositions.find(
    (position) =>
      position.template_values.movableElementId ===
      `note--${view?.document_page_order}`,
  )

  return (
    <DocumentTemplate
      id={`page-${pageId}`}
      title={partName}
      assemblyGroupId={assemblyGroupId}
      order={1}
      documentPageId={documentPage?.id as string}
    >
      <AnnotationsContainer documentPage={documentPage} isReadOnly={isReadOnly}>
        <div className="grid grid-cols-2 gap-4">
          {!notePosition?.template_values.removed && (
            <OperationNotesEditor
              documentId={documentId}
              projectId={projectId}
              assemblyGroupId={documentPage.assembly_group_id}
              documentPageId={documentPage.id ?? ''}
              viewOrImageId={view?.id ?? ''}
              isAddingAnnotation={isAddingAnnotation}
              isReadOnly={isReadOnly}
              positionData={notePosition?.template_values.position}
              size={notePosition?.template_values.size}
              type="note"
              draggableId={`note--${view?.document_page_order}`}
              imageId={view?.id as string}
            />
          )}
          {view && view.download_url && (
            <DraggableImage
              className="!max-h-full !h-full w-auto"
              documentPageId={documentPage?.id as string}
              userFacingDownloadUrl={userFacingDownloadUrl}
              isAddingAnnotation={isAddingAnnotation}
              downloadUrl={downloadUrl}
              draggableId={`view--${view.document_page_order}`}
              imageId={view.id as string}
              positionData={viewPosition?.template_values.position}
              type="view"
              isReadOnly={isReadOnly}
              timestamp={view.created}
            />
          )}
        </div>
      </AnnotationsContainer>
    </DocumentTemplate>
  )
}
