import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import dayjs from 'dayjs'
import { useShallow } from 'zustand/react/shallow'
import { PDFify } from '@/components/pdf/PDFify'
import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline'

import { useCADPageStore } from '@/pages/CADPage/state'
import { useDocumentState } from '@/state'
import logo from '@/assets/q20-logo5.png'
import { useListComments } from '@/services/queries/comments'
import { Button } from '@/components/ui/button'

type DocumentTemplateProps = {
  id: string
  children: ReactNode
  lastUpdated?: string
  title?: string
  versionName?: string
  assemblyGroupId?: string
  order: number
  documentPageId?: string
  isLatestDocumentVersion?: boolean
}
export const DocumentTemplate = ({
  id,
  children,
  lastUpdated,
  title,
  versionName,
  assemblyGroupId,
  order,
  documentPageId,
  isLatestDocumentVersion,
}: DocumentTemplateProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(false)
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const selectedParts = useCADPageStore((state) => state.selectedParts)
  const setCurrentDocumentPageId = useDocumentState(
    useShallow((state) => state.setCurrentDocumentPageId),
  )
  const setCurrentPageInView = useDocumentState(
    useShallow((state) => state.setCurrentPageInView),
  )
  const zoom = useDocumentState((state) => state.zoom)

  const { data: documentPageComments } = useListComments({
    documentPageId,
  })
  const unresolvedComments = documentPageComments?.filter(
    (comment) => comment.comment_status === 'unresolved',
  )
  const setCommentsEnabled = useDocumentState(
    (state) => state.setCommentsEnabled,
  )

  const intersectionHandler = useCallback(
    ([entry]: Array<IntersectionObserverEntry>) => {
      setIsVisible(entry.isIntersecting)
    },
    [setIsVisible],
  )

  const observerOptions = useMemo(
    () => ({
      root: null,
      rootMargin: '40px',
      threshold: 0.7 / (zoom / 100),
    }),
    [zoom],
  )

  const intersectionObserver = useMemo(
    () => new IntersectionObserver(intersectionHandler, observerOptions),
    [observerOptions, intersectionHandler],
  )

  useEffect(() => {
    const container = containerRef.current

    if (!container) return

    intersectionObserver.observe(container)
    return () => {
      if (container) intersectionObserver.unobserve(container)
    }
  }, [containerRef, intersectionObserver])

  useEffect(() => {
    if (isVisible) {
      setCurrentDocumentPageId(documentPageId)
      if (assemblyGroupId) {
        setCadPageState({
          selectedParts: [assemblyGroupId],
        })
      }

      setCurrentPageInView(id)
    }
  }, [isVisible, assemblyGroupId, id, setCadPageState, setCurrentPageInView])

  useEffect(() => {
    const container = containerRef.current
    if (!container || selectedParts.length !== 1) return
    const selectedPart = selectedParts[0]

    if (selectedPart === assemblyGroupId) {
      container.scrollIntoView()
    }
  }, [containerRef, assemblyGroupId])

  return (
    <div
      id={id}
      ref={containerRef}
      className="relative bg-white flex flex-col justify-between h-[612px] w-[792px]"
      style={{
        boxShadow:
          'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
      }}
    >
      {(unresolvedComments?.length || 0) > 0 && (
        <Button
          variant="ghost"
          className="!h-auto px-2 absolute top-0 right-[-40px] hover:bg-transparent"
          onClick={() => setCommentsEnabled(true)}
        >
          <div className="flex flex-col items-center space-y-2 hover:text-black cursor-pointer relative text-slate-400">
            <span className="absolute top-[2px] right-[-5px] text-xs">
              {unresolvedComments?.length}
            </span>
            <ChatBubbleOvalLeftEllipsisIcon className="w-6 h-6" />
          </div>
        </Button>
      )}
      <PDFify id={id} order={order}>
        <div className="flex flex-col flex-1 px-6 pt-6 h-full">
          <div className="flex items-center justify-between mb-2">
            {title ? (
              <h1 className="text-lg font-semibold document-title">{title}</h1>
            ) : (
              <div></div>
            )}
            <div className="flex flex-col self-end text-right text-xs">
              {versionName && !isLatestDocumentVersion && (
                <div className="font-semibold">{versionName}</div>
              )}
              {typeof lastUpdated === 'string' && (
                <div className="text-gray-500">
                  Last updated: {dayjs(lastUpdated).format('MMM DD, YYYY')}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col flex-1">{children}</div>
        </div>
        <div className="flex items-center justify-center text-xs text-center absolute bottom-0 left-0 right-0 pb-0 text-gray-300">
          Made with
          <img src={logo} style={{ height: 40 }} />
        </div>
      </PDFify>
    </div>
  )
}
