import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

import {
  createDocumentVersion,
  listDocumentVersions,
  getDocumentVersion,
  DocumentVersion,
} from '@/lib/api/client'
import queryClient from '@/queryClient'
import MUTATION_KEYS from './mutationKeys'
import QUERY_KEYS from './queryKeys'

type mutationDocumentVersionProps = {
  onSuccess?: (documentVersion?: DocumentVersion) => void
}

export const useCreateDocumentVersion = (
  props?: mutationDocumentVersionProps,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_DOCUMENT_VERSION],
    mutationFn: async ({
      documentId,
      comment,
      name,
    }: {
      documentId: string
      comment?: string
      name?: string
    }) => {
      const documentVersion = await createDocumentVersion(
        documentId,
        comment,
        name,
      )
      return documentVersion
    },
    onSuccess: (documentVersion) => {
      queryClient.setQueryData(
        [
          QUERY_KEYS.DOCUMENT_VERSIONS,
          { documentId: documentVersion.document },
        ],
        // prettier-ignore
        (documentVersions: Array<DocumentVersion> | undefined) => {
          if (documentVersions) {
            [...documentVersions, documentVersion]
          }
        },
      )
      queryClient.setQueryData(
        [QUERY_KEYS.DOCUMENT_VERSION, { documentVersion: documentVersion?.id }],
        documentVersion,
      )
      if (props?.onSuccess) props.onSuccess(documentVersion)
    },
  })
}

export const useListDocumentVersions = ({
  documentId,
}: {
  documentId: string
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.DOCUMENT_VERSIONS, { documentId }],
    queryFn: () => listDocumentVersions(documentId),
  })
}

export const listDocumentVersionsQuery = ({
  documentId,
}: {
  documentId: string
}) => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.DOCUMENT_VERSIONS, { documentId }],
    queryFn: () => listDocumentVersions(documentId),
  })
}

export const getLatestDocumentVersion = async ({
  documentId,
}: {
  documentId: string
}) => {
  const documentVersions = await listDocumentVersions(documentId)
  const latestVersion =
    documentVersions.length > 0
      ? documentVersions[documentVersions.length - 1]
      : null

  if (!latestVersion) {
    throw new Error('No CAD versions found')
  }

  if (!latestVersion.id) {
    throw new Error('No CAD version ID found')
  }

  return latestVersion
}

export const useGetDocumentVersion = ({
  documentVersionId,
}: {
  documentVersionId: string
}) => {
  const queryClient = useQueryClient()
  return useQuery({
    queryKey: [QUERY_KEYS.DOCUMENT_VERSION, { documentVersionId }],
    queryFn: async () => {
      const documentVersion = await getDocumentVersion(documentVersionId)

      if (documentVersion) {
        const documentId = documentVersion.document
        queryClient.setQueryData(
          [QUERY_KEYS.DOCUMENT_VERSIONS, { documentId }],
          (documentVersions: Array<DocumentVersion> | undefined) => {
            if (documentVersions) {
              const documentVersionExists = !!documentVersions.find(
                (currentDocumentVersion) =>
                  currentDocumentVersion.id === documentVersionId,
              )
              return documentVersionExists
                ? documentVersions.map((currentDocumentVersion) =>
                    currentDocumentVersion.id === documentVersion.id
                      ? documentVersion
                      : currentDocumentVersion,
                  )
                : [...documentVersions, documentVersion]
            }
          },
        )
      }

      return documentVersion
    },
  })
}

export const useGetLatestDocumentVersion = ({
  documentId,
}: {
  documentId?: string | null
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.DOCUMENT_VERSION, { documentId }],
    queryFn: async () =>
      documentId ? getLatestDocumentVersion({ documentId }) : null,
    enabled: !!documentId,
  })
}

export { QUERY_KEYS, MUTATION_KEYS }
