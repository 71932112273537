import { useRef, useEffect, useMemo, useCallback, useState } from 'react'
import debounce from 'lodash.debounce'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu'
import { useCADPageStore } from '@/pages/CADPage/state'
import { cn } from '@/utils'
import { AnnotationsToolbarMenuItem, Icon } from './AnnotationsToolbarMenuItem'
import { DEFAULT_MENUS, AnnotationsMenuItem } from './menus'
import {
  DEFAULT_STROKE_COLOR,
  DISABLED_STROKE_COLOR,
  STROKE_COLOR_VARIATIONS,
} from './constants'
import { useAnnotationsQuery } from './queries'
import { useAnnotations } from './hooks'
import { AnnotationSizes, IconStrokes } from './enums'
import { CreateDocumentVersionButton } from '@/components/versions/CreateDocumentVersionButton'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { ColorPallete } from '@/components/icons/ColorPallete'
import { CommentsButton } from '../CommentsButton'
import { useDocumentState } from '@/state'

export const AnnotationsToolbar = () => {
  const documentPage = useDocumentPageQuery()
  const [annotationMenus, setAnnotationMenus] = useState(DEFAULT_MENUS)
  const selectedParts = useCADPageStore((state) => state.selectedParts)
  const currentDocumentPageId = useDocumentState(
    (state) => state.currentDocumentPageId,
  )

  const annotationsToolbarRef = useRef<HTMLDivElement>(null)
  const {
    isAddingAnnotation,
    newAnnotation,
    setRefPosition,
    canDropAnnotation,
  } = useAnnotationsQuery()
  const {
    Icon: IconPlaceholder,
    id: iconPlaceholderId,
    size: iconPlaceholderSize,
    strokeColor: iconPlaceholderColor,
    startPoint: iconPlaceholderStartPoint,
  } = newAnnotation || {}

  useAnnotations()

  useEffect(() => {
    setAnnotationMenus((prev) => {
      const bubblesMenu = {
        id: 'bubbles',
        columns: 4,
        TriggerIcon: BubblesMenuItem('A') as any,
        menuItems: [] as any,
      }
      const menuItems = Array.from(Array(26)).map((_, index) => ({
        id: `bubble-${index + 1}`,
        Icon: BubblesMenuItem(`${String.fromCharCode(65 + index)}`) as any,
        strokeColor: 'black',
        bubbleValue: String.fromCharCode(65 + index),
      }))
      bubblesMenu.menuItems = menuItems
      return [...prev, bubblesMenu]
    })

    return () => {
      setAnnotationMenus(DEFAULT_MENUS)
    }
  }, [])

  const onResizeHandler = useCallback(
    () =>
      debounce(([entry]: Array<ResizeObserverEntry>) => {
        const rect = entry.target.getBoundingClientRect()
        setRefPosition({ x: rect.x, y: rect.y })
      }, 300),
    [setRefPosition],
  )

  const resizeObserver = useMemo(
    () => new ResizeObserver(onResizeHandler()),
    [onResizeHandler],
  )

  useEffect(() => {
    const annotationsToolbar = annotationsToolbarRef.current
    if (annotationsToolbar && selectedParts.length) {
      resizeObserver.observe(annotationsToolbar)
    }

    return () => {
      if (annotationsToolbar) resizeObserver.unobserve(annotationsToolbar)
    }
  }, [annotationsToolbarRef, selectedParts, resizeObserver])

  return (
    <div
      className="flex absolute z-30 top-0 left-0 w-full bg-white h-12 shadow-md shadow-stone-200/70"
      ref={annotationsToolbarRef}
    >
      {isAddingAnnotation && IconPlaceholder && (
        <div
          className={cn(
            'fixed z-50 pointer-events-none p-0.5 box-content border-2 border-transparent',
            iconPlaceholderSize &&
              (AnnotationSizes[iconPlaceholderSize] ??
                `h-${iconPlaceholderSize} w-${iconPlaceholderSize}`),
          )}
          id={`icon-placeholder--${iconPlaceholderId}`}
        >
          {!iconPlaceholderStartPoint && (
            <IconPlaceholder
              className="h-full w-full stroke-1 opacity-60"
              strokeWidth={
                IconStrokes[iconPlaceholderSize || ''] ?? IconStrokes['small']
              }
              strokeColor={
                canDropAnnotation
                  ? iconPlaceholderColor || DEFAULT_STROKE_COLOR
                  : DISABLED_STROKE_COLOR
              }
            />
          )}
        </div>
      )}
      <div className="flex items-center mx-4">
        {annotationMenus.map((menu) => (
          <AnnotationsToolbarMenu
            key={menu.id}
            menuItems={menu.menuItems}
            columns={menu.columns}
            colorOptionsEnabled={menu.colorOptionsEnabled}
            TriggerIcon={menu.TriggerIcon}
            isDisabled={
              !currentDocumentPageId ||
              isAddingAnnotation ||
              !documentPage.data?.isLatestDocumentVersion
            }
          />
        ))}
        <CommentsButton />
        <CreateDocumentVersionButton
          className="ml-4"
          disabled={!documentPage.data?.isLatestDocumentVersion}
        />
      </div>
    </div>
  )
}

const BubblesMenuItem = (value: string) => {
  const Component = () => (
    <div className="flex items-center justify-center py-2 px-3">
      <span
        className="flex border font-bold border-black shadow-none m-0 text-xs h-5 w-5 rounded-full justify-center items-center text-xs p-2"
        style={{ borderWidth: 1.5 }}
      >
        {value}
      </span>
    </div>
  )

  return Component
}

type AnnotationsToolbarMenuProps = {
  menuItems: Array<AnnotationsMenuItem>
  columns?: number
  colorOptionsEnabled?: boolean
  TriggerIcon: Icon
  isDisabled: boolean
}
const AnnotationsToolbarMenu = ({
  menuItems,
  columns,
  colorOptionsEnabled,
  TriggerIcon,
  isDisabled,
}: AnnotationsToolbarMenuProps) => {
  const [selectedColor, setSelectedColor] = useState(DEFAULT_STROKE_COLOR)
  const [showColorOptions, setShowColorOptions] = useState(false)

  useEffect(() => {
    setShowColorOptions(false)
  }, [selectedColor])

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        disabled={isDisabled}
        className={cn('mx-2 rounded-md hover:bg-gray-100 h-5/6', {
          'opacity-25': isDisabled,
        })}
      >
        <TriggerIcon className="h-full" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="flex flex-col min-w-0 max-w-44 px-1.5 py-2">
        {showColorOptions && (
          <div
            className="absolute max-w-[80px] inset-x-0 mx-auto bg-white border shadow rounded-md"
            style={{ transform: 'translateY(-105%)' }}
          >
            <AnnotationsColorOptions
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
            />
          </div>
        )}
        {colorOptionsEnabled && (
          <div className="flex items-center justify-center mb-2">
            <span
              className="rounded-full m-1 text-center text-xs cursor-pointer"
              onClick={() => setShowColorOptions(!showColorOptions)}
            >
              <ColorPallete className="h-5 w-5" />
            </span>
          </div>
        )}
        <div
          className="grid min-w-0 max-w-44 gap-2"
          style={{
            gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
            overflow: 'visible',
          }}
        >
          {menuItems.map((menuItem) => (
            <DropdownMenuItem
              key={`${menuItem.id}${menuItem.size ? `-${menuItem.size}` : ''}`}
              className="w-full h-full flex items-center justify-center p-0"
            >
              <AnnotationsToolbarMenuItem
                strokeColor={selectedColor}
                {...menuItem}
              />
            </DropdownMenuItem>
          ))}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

type AnnotationsColorOptionsProps = {
  selectedColor: string
  setSelectedColor: React.Dispatch<React.SetStateAction<string>>
}
const AnnotationsColorOptions = ({
  selectedColor,
  setSelectedColor,
}: AnnotationsColorOptionsProps) => {
  return (
    <div className="flex mt-1 mb-2 justify-center flex-wrap ">
      {[DEFAULT_STROKE_COLOR, ...STROKE_COLOR_VARIATIONS].map((color) => (
        <span
          className={cn(
            'rounded-full h-4 w-4 m-1 text-center cursor-pointer hover:scale-125 transition-transform ease-in-out duration-100 border flex items-center justify-center text-xs',
          )}
          key={`color-option--${color}`}
          style={{
            backgroundColor: color,
            color: selectedColor === color ? 'white' : color,
            boxSizing: 'border-box',
          }}
          onClick={() => {
            setSelectedColor(color)
          }}
        >
          &#10003;
        </span>
      ))}
    </div>
  )
}
