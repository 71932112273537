import { DocumentTemplate } from './DocumentTemplate'

export const TitleDocument = ({
  documentName,
  versionName,
  lastUpdated,
  isLatestDocumentVersion,
}: {
  documentName: string
  versionName: string
  lastUpdated: string
  isLatestDocumentVersion?: boolean
}) => {
  return (
    <DocumentTemplate
      id="cover"
      lastUpdated={lastUpdated}
      versionName={versionName}
      order={1}
      isLatestDocumentVersion={isLatestDocumentVersion}
    >
      <div className="mt-20 flex flex-col space-y-2">
        <h1 className="text-3xl font-bold">{documentName}</h1>
        <h2 className="text-lg">Assembly instructions</h2>
      </div>
    </DocumentTemplate>
  )
}
