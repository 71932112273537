import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import { TableCell } from '@/components/ui/table'
import { useAssemblyTree, useDocumentState } from '@/state'
import { DocumentTemplate } from './DocumentTemplate'
import { OperationTableData, OperationTable } from './OperationTable'
import { ToolsTable } from './ToolsTable'
import type { DocumentPage, View } from '@/lib/api/client'
import { useDocumentPageQuery } from '../queries'
import { useAnnotationsQuery } from './Annotations/queries'
import {
  useImagesPositions,
  useListImageTemplates,
  useViewsPositions,
  useNotesPositions,
  useImagePlaceholderPositions,
} from '@/services/hooks/template_attributes'
import { useListImages } from '@/services/queries/images'
import { useUpdateCadVersionMutation } from '@/pages/CADPage/queries'
import { useCADQuery } from '@/services/queries/cads'
import { Input } from '@/components/ui/input'
import { OperationNotesEditor } from './OperationNotesEditor/OperationNotesEditor'
import DraggableImage from './DraggableImage'
import ImagePlaceholder from './ImagePlaceholder'
import { AnnotationsContainer } from './Annotations/AnnotationsContainer'

const MAX_VIEW_COUNT = 2
const OPERATION_DOCUMENTS_OFFSET = 1000000

export const OperationDocument = ({
  documentPage,
  views,
  versionName,
  lastUpdated,
  orderNumber,
  isReadOnly,
  isLatestDocumentVersion,
}: {
  documentPage: DocumentPage
  views: View[]
  versionName: string
  lastUpdated: string
  orderNumber: number
  isReadOnly: boolean
  isLatestDocumentVersion?: boolean
}) => {
  const { data: docData } = useDocumentPageQuery()
  const { isAddingAnnotation } = useAnnotationsQuery()
  const setSelectedElement = useDocumentState(
    (state) => state.setSelectedElement,
  )
  const selectedElement = useDocumentState((state) => state.selectedElement)
  const partsInOperation = useAssemblyTree((state) =>
    state.getBillOfMaterialsForPart(documentPage.assembly_group_id),
  )
  const { data: images } = useListImages({
    documentVersionId: documentPage.document_version,
  })
  const imagesPositions = useImagesPositions(documentPage.id)
  const viewsPositions = useViewsPositions(documentPage.id)
  const notesPositions = useNotesPositions(documentPage.id)
  const imagePlaceholderPositions = useImagePlaceholderPositions(
    documentPage.id,
  )
  const genericPlaceholderPosition = imagePlaceholderPositions.find(
    (position) =>
      position.template_values.movableElementId === 'generic-placeholder',
  )

  const imageTemplates = useListImageTemplates(documentPage.id)

  const unselectElement = useCallback(() => {
    setSelectedElement(null)
  }, [setSelectedElement])

  useEffect(() => {
    if (selectedElement) {
      document.addEventListener('click', unselectElement)
    } else {
      document.removeEventListener('click', unselectElement)
    }

    return () => {
      document.removeEventListener('click', unselectElement)
    }
  }, [unselectElement, selectedElement])

  const selectTable = useCallback(
    (tableId: string) => {
      setSelectedElement({
        documentPageId: documentPage.id as string,
        id: tableId,
        isRemovable: false,
      })
    },
    [setSelectedElement, documentPage],
  )

  const initialTableColumns = useMemo<ColumnDef<OperationTableData>[]>(
    () => [
      {
        accessorKey: 'partName',
        header: 'Part Name',
        size: 200,
        enableResizing: true,
        cell: ({ getValue, table, column }) =>
          isReadOnly ? (
            <TableCell
              className="items-center border-x border-solid border-gray-200 border-collapse text-xs py-1 bg-white/70"
              style={{
                position: 'relative',
                width: column.getSize(),
              }}
            >
              {getValue<any>().partName}
            </TableCell>
          ) : (
            <NameCell
              assemblyGroupId={documentPage.assembly_group_id}
              getValue={getValue}
              selectTable={selectTable}
              tableId={(table.options.meta as any).tableId}
              width={column.getSize()}
            />
          ),
      },
      {
        accessorKey: 'quantity',
        header: 'Qty',
        size: 200,
        enableResizing: true,
        cell: ({ getValue, column }) => (
          <TableCell
            className="items-center border-x border-solid border-gray-200 border-collapse text-xs py-1 bg-white/70"
            style={{
              position: 'relative',
              width: column.getSize(),
            }}
          >
            <span className="px-1">{getValue<string>()}</span>
          </TableCell>
        ),
      },
    ],
    [documentPage.assembly_group_id, selectTable],
  )

  const initialTableData = useMemo(
    () =>
      partsInOperation.map<OperationTableData>((part) => ({
        partName: part,
        quantity: part.quantity,
        assemblyGroupId: documentPage.assembly_group_id,
      })),
    [partsInOperation, documentPage],
  )

  const imageIds = useMemo(
    () =>
      imageTemplates.map(
        (imageTemplate) => imageTemplate.template_values.imageId,
      ),
    [imageTemplates],
  )
  const documentImages = useMemo(
    () =>
      (images || []).filter(
        (image) => imageIds.findIndex((id) => id === image.id) >= 0,
      ),
    [imageIds, images],
  )

  const totalViewsAndImage = useMemo(
    () => views.length + documentImages.length,
    [views, documentImages],
  )

  if (
    !docData ||
    !docData.project ||
    !docData.version ||
    !docData.documentPages
  ) {
    return null
  }

  return (
    <DocumentTemplate
      id={`op-${documentPage.id}`}
      title={`Operation ${orderNumber}`}
      lastUpdated={lastUpdated}
      versionName={versionName}
      isLatestDocumentVersion={isLatestDocumentVersion}
      assemblyGroupId={documentPage.assembly_group_id}
      order={OPERATION_DOCUMENTS_OFFSET + orderNumber}
      documentPageId={documentPage.id as string}
    >
      <AnnotationsContainer documentPage={documentPage} isReadOnly={isReadOnly}>
        {partsInOperation.length > 0 && (
          <div className="flex justify-between">
            <OperationTable
              initialColumns={initialTableColumns}
              initialTableData={initialTableData}
              assemblyGroupId={documentPage.assembly_group_id}
              documentPage={documentPage}
              isReadOnly={isReadOnly}
            />
            <ToolsTable
              assemblyGroupId={documentPage.assembly_group_id}
              documentPage={documentPage}
              isReadOnly={isReadOnly}
            />
          </div>
        )}
        <div
          className="flex flex-1 justify-between"
          style={{
            maxHeight: 360,
          }}
        >
          {views.map((view) => {
            const downloadUrl = view.download_url
            const userFacingDownloadUrl = `${view.name}.jpg`

            const viewPosition = viewsPositions.find(
              (position) =>
                position.template_values.movableElementId ===
                `view--${view.document_page_order}`,
            )
            const notePosition = notesPositions.find(
              (position) =>
                position.template_values.movableElementId ===
                `note--${view.document_page_order}`,
            )

            return (
              downloadUrl && (
                <div
                  key={view.id}
                  className="flex flex-col flex-1 justify-between"
                  style={{
                    maxWidth: '49%',
                  }}
                >
                  <DraggableImage
                    documentPageId={documentPage?.id as string}
                    userFacingDownloadUrl={userFacingDownloadUrl}
                    isAddingAnnotation={isAddingAnnotation}
                    downloadUrl={downloadUrl}
                    draggableId={`view--${view.document_page_order}`}
                    imageId={view.id as string}
                    positionData={viewPosition?.template_values.position}
                    type="view"
                    isReadOnly={isReadOnly}
                  />
                  {!notePosition?.template_values.removed && (
                    <OperationNotesEditor
                      documentId={docData.documentId ?? ''}
                      projectId={docData?.project.id ?? ''}
                      assemblyGroupId={documentPage.assembly_group_id}
                      documentPageId={documentPage.id ?? ''}
                      viewOrImageId={view.id ?? ''}
                      isAddingAnnotation={isAddingAnnotation}
                      isReadOnly={isReadOnly}
                      positionData={notePosition?.template_values.position}
                      size={notePosition?.template_values.size}
                      type="note"
                      draggableId={`note--${view.document_page_order}`}
                      imageId={view.id as string}
                    />
                  )}
                </div>
              )
            )
          })}
          {documentImages.map((image) => {
            const imagePosition = imagesPositions.find(
              (position) =>
                position.template_values.movableElementId ===
                `image--${image.id}`,
            )
            const notePosition = notesPositions.find(
              (position) =>
                position.template_values.movableElementId ===
                `note--${image.id}`,
            )
            const downloadUrl = image.download_url as string
            const userFacingDownloadUrl = `${image.name}.jpg`

            return (
              <div
                key={image.id}
                className="flex flex-1 flex-col justify-between space-y-2"
                style={{
                  maxWidth: '49%',
                }}
              >
                <DraggableImage
                  documentPageId={documentPage?.id as string}
                  userFacingDownloadUrl={userFacingDownloadUrl}
                  isAddingAnnotation={isAddingAnnotation}
                  downloadUrl={downloadUrl}
                  draggableId={`image--${image.id}`}
                  imageId={image.id as string}
                  positionData={imagePosition?.template_values.position}
                  type="image"
                  isReadOnly={isReadOnly}
                />
                <OperationNotesEditor
                  documentId={docData.documentId ?? ''}
                  projectId={docData?.project.id ?? ''}
                  assemblyGroupId={documentPage.assembly_group_id}
                  documentPageId={documentPage.id ?? ''}
                  viewOrImageId={image.id ?? ''}
                  isAddingAnnotation={isAddingAnnotation}
                  isReadOnly={isReadOnly}
                  positionData={notePosition?.template_values.position}
                  size={notePosition?.template_values.size}
                  type="note"
                  draggableId={`note--${image.id}`}
                  imageId={image.id as string}
                />
              </div>
            )
          })}
          {totalViewsAndImage < MAX_VIEW_COUNT &&
            !isReadOnly &&
            !genericPlaceholderPosition?.template_values.removed && (
              <ImagePlaceholder
                projectId={docData?.project.id as string}
                documentId={docData.documentId as string}
                documentPage={documentPage}
                isAddingAnnotation={isAddingAnnotation}
                draggableId="generic-placeholder"
                positionData={
                  genericPlaceholderPosition?.template_values.position
                }
              />
            )}
        </div>
      </AnnotationsContainer>
    </DocumentTemplate>
  )
}

type NameCellProps = {
  getValue: CellContext<OperationTableData, unknown>['getValue']
  selectTable: (tableId: string) => void
  tableId: string
  assemblyGroupId: string
  width: number
}
const NameCell = ({
  getValue,
  selectTable,
  tableId,
  assemblyGroupId,
  width,
}: NameCellProps) => {
  const [hasChanged, setHasChanged] = useState(false)
  const [partName, setPartName] = useState(getValue<any>().partName || '')
  const part = getValue<any>()

  const renamePart = useAssemblyTree((state) => state.renameNode)
  const getAssemblyTree = useAssemblyTree((state) => state.getAssemblyTree)
  const { mutate: updateCadVersion } = useUpdateCadVersionMutation()
  const { data: cadData } = useCADQuery()

  const cadVersionId = useMemo(
    () => cadData?.version?.id as string,
    [cadData?.version],
  )
  const documentVersionId = useMemo(
    () => cadData?.documentVersion?.id as string,
    [cadData?.documentVersion],
  )
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHasChanged(true)
    const updatedValue = event.target.value
    setPartName(updatedValue)
  }

  const handlePartNameChange = useCallback(() => {
    const UUIDs = part.UUIDs
    UUIDs.forEach((uuid) => {
      renamePart(uuid, partName)
    })
    const assemblyTree = getAssemblyTree()
    if (assemblyTree && cadVersionId && documentVersionId) {
      updateCadVersion({
        cadVersionId,
        documentVersionId,
        assemblyTree,
      })
    }
  }, [
    cadVersionId,
    documentVersionId,
    part.UUIDs,
    partName,
    getAssemblyTree,
    renamePart,
    updateCadVersion,
  ])

  const handleOnBlur = useCallback(() => {
    if (hasChanged) {
      handlePartNameChange()
      setHasChanged(false)
    }
  }, [hasChanged, handlePartNameChange])

  return (
    <TableCell
      id={`part-${assemblyGroupId}-${part.partName.replace(/\s/g, '-')}`}
      className="border-x border-solid border-gray-200 border-collapse py-0 bg-white/70"
      style={{
        position: 'relative',
        width,
      }}
    >
      <Input
        className="border-none shadow-none m-0 p-1 text-xs h-auto"
        value={partName}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onMouseDownCapture={(e) => e.stopPropagation()}
        onClick={(e) => {
          e.stopPropagation()
          selectTable(tableId)
        }}
      />
    </TableCell>
  )
}
