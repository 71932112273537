import { create } from 'zustand'

type selectedElementData = {
  documentPageId: string
  id: string
  isRemovable?: boolean
} | null
interface DocumentState {
  zoom: number
  commentsEnabled: boolean
  selectedElement: selectedElementData
  currentDocumentPageId?: string
  currentPageInView: string
  setZoom: (zoom: number) => void
  setCommentsEnabled: (commentsEnabled: boolean) => void
  setSelectedElement: (elementData: selectedElementData) => void
  setCurrentDocumentPageId: (currentDocumentPageId?: string) => void
  setCurrentPageInView: (currentPageInViewId: string) => void
}

export const useDocumentState = create<DocumentState>((set) => ({
  zoom: 100,
  commentsEnabled: false,
  selectedElement: null,
  currentDocumentPageId: '',
  currentPageInView: '',

  setZoom: (zoom) => set({ zoom }),
  setCommentsEnabled: (commentsEnabled) => set({ commentsEnabled }),
  setSelectedElement: (elementData) =>
    set({
      selectedElement: {
        isRemovable: true,
        ...elementData,
      } as any,
    }),
  setCurrentDocumentPageId: (currentDocumentPageId) =>
    set({ currentDocumentPageId }),
  setCurrentPageInView: (currentPageInView) => set({ currentPageInView }),
}))
